import React from "react"
import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"

const Stat = styled.p`
  font-size: 4rem;
  color: #535353;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
  ${props =>
    props.color &&
    props.theme.colors[props.color] &&
    `color: ${props.theme.colors[props.color]};`}

  span {
    font-family: 'Raleway', sans-serif;
    color: #535353;
    font-size: 1.6rem;
    font-weight: 500;
    padding-left: 0.5rem;
  }

  @media ${sm} {
    span {
      padding-left: 0;
      display: block;
    }
  }

  @media ${md} {
    font-size: 4.8rem;
    span {
      font-size 1.8rem;
    }
  }
`

const TextStatistic = ({ loading, stat, label, color }) => {
  return (
    <Stat color={color}>
      {loading ? "–" : stat}
      <span>{label}</span>
    </Stat>
  )
}

export default TextStatistic

TextStatistic.defaultProps = {
  color: "primary"
}
