import React from "react"
import styled, { keyframes } from "styled-components"
import floatToPercentage from "mill/utils/floatToPercentage"

const animation = (width) => keyframes`
  0% {
    width: 50%;
  }

  100% {
    width: ${width}%;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${props => props.grayscale && "filter: grayscale(1);"}
`

const Bar = styled.div`
  height: 3rem;
  padding: 0 1.5rem;
  position: relative;
  p {
    margin: 0;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 3rem;
    color: #ffffff;
    white-space: nowrap;
    position: absolute;
    top: 0;
  }
`

const LeftBar = styled(Bar)`
  width: ${props => props.width}%;
  background: ${props => props.theme.colors[props.color]};
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  text-align: left;
  animation: ${props => animation(props.width)} 1s ease;
  p {
    color: ${props => props.theme.colors[`${props.color}Font`]};
    left: 1rem;
  }
`

const RightBar = styled(Bar)`
  width: ${props => props.width}%;
  background: ${props => props.theme.colors[props.color]};
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  text-align: right;
  animation: ${props => animation(props.width)} 1s ease;
  p {
    color: ${props => props.theme.colors[`${props.color}Font`]};
    right:  1rem;
  }
`

const Label = styled.small`
  color: #909090;
  font-size: 1.2rem;
  margin-top: 0.8rem;
`

const HorizontalSplitBar = ({ colors, statistics, loading }) => {
  const leftTheme = colors[0]
  const rightTheme = colors[1]

  if (loading || !statistics) {
    return (
      <div>
        <FlexContainer grayscale>
          <LeftBar
            color={leftTheme}
            width={50}
          />
          <RightBar
            color={rightTheme}
            width={50}
          />
        </FlexContainer>
        <FlexContainer>
          <Label>Loading...</Label>
          <Label>Loading...</Label>
        </FlexContainer>
      </div>
    )
  }

  const { primary: primaryStat, secondary: secondaryStat } = statistics
  const primaryPercentage = floatToPercentage(primaryStat.percentage)
  const secondaryPercentage = floatToPercentage(secondaryStat.percentage)

  return (
    <div>
      <FlexContainer>
        <LeftBar
          color={leftTheme}
          width={primaryPercentage}>
          <p>{primaryPercentage}%</p>
        </LeftBar>
        <RightBar
          color={rightTheme}
          width={secondaryPercentage}>
          <p>{secondaryPercentage}%</p>
        </RightBar>
      </FlexContainer>
      <FlexContainer>
        <Label>{primaryStat.label}</Label>
        <Label>{secondaryStat.label}</Label>
      </FlexContainer>
    </div>
  )
}

export default HorizontalSplitBar

HorizontalSplitBar.defaultProps = {
  colors: ["primary", "secondary"]
}
