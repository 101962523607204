import React, { Fragment, useRef, useEffect, useContext } from "react"
import * as d3 from "d3"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import CurrentUserContext from "shared/utils/CurrentUserContext"
import { useLocation } from "react-router-dom"
import AdminSubNavigation from "shared/components/AdminSubNavigation"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import TextStatistic from "shared/components/TextStatistic"
import HorizontalBarChart from "shared/components/HorizontalBarChart"
import HorizontalSplitBar from "shared/components/HorizontalSplitBar"
import ProgressBar from "shared/components/ProgressBar"
import HorizontalRule from "shared/components/HorizontalRule"
import Wrapper from "shared/components/Wrapper"
import Overlay, { OverlayContainer } from "shared/components/Overlay"
import usePageTitle from "mill/hooks/usePageTitle"
import useFilters from "shared/hooks/useFilters"
import InsightsHeader from "mill/components/InsightsHeader"
import { sm, md } from "mill/utils/breakpoints"
import floatToPercentage from "mill/utils/floatToPercentage"
import { ninetyDaysago, today } from "shared/utils/dateHelpers"

import FETCH_ACCOUNT_ENGAGEMENT_INSIGHTS from "mill/graphql/FetchAccountEngagementInsights"

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2rem;
  align-items: center;

  > div:first-of-type {
    padding-bottom: 2rem;
  }

  @media ${sm} {
    grid-template-columns: 15rem 1fr;
  }
  @media ${md} {
    grid-template-columns: 20rem 1fr;
  }
`

const Engagement = () => {
  usePageTitle("Engagement")
  const { currentUser } = useContext(CurrentUserContext)
  const { search } = useLocation()
  const { getParamValue } = useFilters()
  const accountId = currentUser.account.id
  const { loading, data, variables } = useQuery(
    FETCH_ACCOUNT_ENGAGEMENT_INSIGHTS,
    {
      variables: {
        id: accountId,
        startDate: getParamValue("activeStart") || ninetyDaysago,
        endDate: getParamValue("activeEnd") || today,
        teamIds: getParamValue("teamIds"),
        teamGroupingIds: getParamValue("groupIds"),
        campaignCollectionIds: getParamValue("collectionIds")
      }
    }
  )

  const noCampaignsRun = data?.accountInsights?.campaignsRunCount === 0

  const campaignCountStats = data?.accountInsights && {
    primary: {
      label: `${data.accountInsights.embedCampaignCount} Embed campaigns`,
      percentage: data.accountInsights.embedCampaignPercentage
    },
    secondary: {
      label: `${data.accountInsights.burstCampaignCount} Burst campaigns`,
      percentage: data.accountInsights.burstCampaignPercentage
    }
  }

  const learnerCountStats = data?.accountInsights && {
    primary: {
      label: `${data.accountInsights.activeLearnerCount} Active learners`,
      percentage: data.accountInsights.activeLearnerPercentage
    },
    secondary: {
      label: `${data.accountInsights.totalLearnerCount} Total learners`,
      percentage: data.accountInsights.inactiveLearnerPercentage
    }
  }

  const items = [
    { title: "Engagement", path: `/admin/insights/engagement${search}` },
    {
      title: "Performance & Knowledge",
      path: `/admin/insights/performance-knowledge${search}`
    }
  ]

  const campaignParticipation =
    data?.accountInsights?.campaignParticipationList || []
  const campaignCompletion =
    data?.accountInsights?.campaignCompletionList || []
  const campaignActiveCompletion =
    data?.accountInsights?.campaignActiveCompletionList || []
  const averageParticipation = data?.accountInsights
    ? floatToPercentage(data.accountInsights.averageLearnerParticipationRate)
    : 0
  const averageCompletion = data?.accountInsights
    ? floatToPercentage(data.accountInsights.averageLearnerCompletionRate)
    : 0
  const averageActiveCompletion = data?.accountInsights
    ? floatToPercentage(data.accountInsights.averageLearnerActiveCompletionRate)
    : 0

  return (
    <Fragment>
      <AdminSubNavigation items={items} />
      <Wrapper>
        <Box paddingTop="large" maxWidth="large">
          <Heading level={1}>Engagement</Heading>
        </Box>

        <Box paddingBottom="xlarge">
          <InsightsHeader
            startDate={variables.startDate}
            endDate={variables.endDate}
          />
        </Box>

        <OverlayContainer>
          <Grid>
            <Box>
              <TextStatistic
                stat={data && data.accountInsights.campaignsRunCount}
                loading={loading}
                label="Campaigns run"
                color="primary"
              />
            </Box>
            <HorizontalSplitBar
              colors={["primary", "tertiary"]}
              statistics={campaignCountStats}
              loading={loading || noCampaignsRun}
            />
          </Grid>

          <Box paddingTop="large" paddingBottom="large">
            <HorizontalRule />
          </Box>

          <Grid>
            <Box>
              <TextStatistic
                stat={data && data.accountInsights.activeLearnerCount}
                loading={loading}
                label="Active learners"
                color="quaternary"
              />
            </Box>
            <ProgressBar color="quaternary" statistics={learnerCountStats} />
          </Grid>

          <Box paddingTop="large" paddingBottom="large">
            <HorizontalRule />
          </Box>

          <Box paddingTop="medium" paddingBottom="medium">
            <Grid>
              <Box>
                <TextStatistic
                  stat={`${averageParticipation}%`}
                  loading={loading}
                  label="Avg participation rate"
                  color="quinary"
                />
              </Box>
              <HorizontalBarChart
                data={campaignParticipation}
                dataKeys={["participationRate"]}
                colors={["quinary"]}
              />
            </Grid>
          </Box>

          <Box paddingTop="large" paddingBottom="large">
            <HorizontalRule />
          </Box>

          <Box paddingTop="medium" paddingBottom="large">
            <Grid>
              <Box>
                <TextStatistic
                  stat={`${averageCompletion}%`}
                  loading={loading}
                  label="Avg completion rate"
                  color="senary"
                />
              </Box>
              <HorizontalBarChart
                data={campaignCompletion}
                dataKeys={["completionRate"]}
                colors={["senary"]}
              />
            </Grid>
          </Box>

          <Box paddingTop="large" paddingBottom="large">
            <HorizontalRule />
          </Box>

          <Box paddingTop="medium" paddingBottom="large">
            <Grid>
              <Box>
                <TextStatistic
                  stat={`${averageActiveCompletion}%`}
                  loading={loading}
                  label="Avg active completion rate"
                  color="tertiary"
                />
              </Box>
              <HorizontalBarChart
                data={campaignActiveCompletion}
                dataKeys={["activeCompletionRate"]}
                colors={["tertiary"]}
              />
            </Grid>
          </Box>

          {data && data.accountInsights.campaignsRunCount === 0 && (
            <Overlay>
              <Box textAlign="center">
                <Heading level={2}>
                  No campaign data for the selected filters
                </Heading>
                <Box paddingTop="small">
                  <Text>Please change the filters above.</Text>
                </Box>
              </Box>
            </Overlay>
          )}
        </OverlayContainer>
      </Wrapper>
    </Fragment>
  )
}

export default Engagement
