import React from "react"
import styled, { keyframes } from "styled-components"
import floatToPercentage from "mill/utils/floatToPercentage"

const progress = width => keyframes`
  0% {
    width: 0%;
  }

  100% {
    width: ${width}%;
  }
`

const Container = styled.div`
  position: relative;
  ${props => props.grayscale && "filter: grayscale(1);"}
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Bar = styled.div`
  height: 3rem;
  padding: 0 1.5rem;
  border-radius: 2rem;
`

const Underlay = styled(Bar)`
  width: 100%;
  background: #fcfcfc;
  border-radius: 2rem;
  text-align: left;
`

const ProgressBar = styled(Bar)`
  position: absolute;
  top: 0;
  left: 0;
  background: ${props => props.theme.colors[props.color]};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  text-align: right;
  width: ${props => props.width}%;
  animation: ${props => progress(props.width)} 1s ease;
`

const Percentages = styled(FlexContainer)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  p {
    margin: 0;
    position: absolute;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 3rem;
    color: #ffffff;

    &:nth-of-type(1) {
      left: 1rem;
    }
    &:nth-of-type(2) {
      color: #c4c4c4;
      right: 1rem;
    }
  }
`

const Label = styled.small`
  color: #909090;
  font-size: 1.2rem;
  margin-top: 0.8rem;
`

const Component = ({ color, statistics, loading }) => {
  if (loading || !statistics) {
    return (
      <Container grayscale>
        <Underlay />
        <ProgressBar
          color={color}
          width={0}
        />
        <FlexContainer>
          <Label>Loading...</Label>
          <Label>Loading...</Label>
        </FlexContainer>
      </Container>
    )
  }

  const { primary: primaryStat, secondary: secondaryStat } = statistics
  const primaryPercentage = floatToPercentage(primaryStat.percentage)
  const secondaryPercentage = floatToPercentage(secondaryStat.percentage)

  return (
    <Container>
      <Underlay />
      <ProgressBar
        color={color}
        width={primaryPercentage}></ProgressBar>
      <Percentages>
        <p>{primaryPercentage}%</p>
        {secondaryPercentage > 10 && (
          <p>{secondaryPercentage}%</p>
        )}
      </Percentages>
      <FlexContainer>
        <Label>{primaryStat.label}</Label>
        <Label>{secondaryStat.label}</Label>
      </FlexContainer>
    </Container>
  )
}

export default Component

Component.defaultProps = {
  color: "primary"
}
